<template>
  <div>
    <h3 class="center">Свободные смены</h3>
    <div class="heap">
      <table class="t_table">
        <tr>
          <td style="width: 5%">№</td>
          <td style="width: 15%">Дата</td>
          <td style="width: 40%">Точка</td>
          <td>Cмена</td>
          <td style="width: 10%">Часов</td>
        </tr>
      </table>
    </div>

    <div v-if="loading" class="body">
      <Loader />
    </div>
    <div v-else>
      <template v-if="this.total > 0">
        <table class="t_table">
          <template v-for="(row, y) in report">
            <tr :key="y">
              <td style="width: 5%">{{ y + 1 }}</td>
              <td class="center" style="width: 15%">
                {{ dateFormatHuman(row.turn_date) }}
              </td>
              <td style="width: 40%">
                {{ row.point }}
              </td>
              <td>
                {{ row.time_begin }} &mdash; {{ row.time_end }}
                {{ !row.turn_order ? "🌝" : "\u00A0\u00A0\u00A0\u00A0" }}
              </td>
              <td style="width: 10%">
                {{ row.hours }} {{ row.hours_add ? `(+${row.hours_add})` : null }}
              </td>
            </tr>
          </template>
          <tr>
            <td colspan="4"></td>
            <td>
              <b>{{ this.total }}</b>
            </td>
          </tr>
        </table>
      </template>

      <template v-if="this.total < 0">
        <table class="t_table">
          <tr>
            <td colspan="5">нет данных по выбранным параметрам</td>
          </tr>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";

export default {
  components: { Loader },
  data() {
    return {
      loading: false,

      report: [],
      total: 0,
    };
  },

  mounted() {
    // retreive data anyway
    this.tableDataRetrieve();
  },

  methods: {
    dateFormatHuman(d) {
      return dateFormatHuman(d);
    },

    async tableDataRetrieve() {
      this.loading = true;

      const data = await request("/api/turn/listfree", "POST");

      this.total = 0;
      for (let i in data) this.total += parseInt(data[i].hours);

      this.report = data;
      this.loading = false;
    },
  },
};
</script>
